/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import {
  AiOutlineMail,
  AiOutlineInstagram,
  AiFillFacebook,
  AiOutlineTwitter,
  AiFillLinkedin,
} from "react-icons/ai"
import { FiGlobe } from "react-icons/fi"

import { Link } from "./Link"
import { ContributorSocialLinkPropTypes } from "../utils/propTypes"

export const SocialLinks = ({
  email_link,
  instagram_link,
  facebook_link,
  twitter_link,
  linkedin_link,
  website_links,
  styles,
}) => (
  <Flex sx={{ ml: "auto", ...styles }}>
    {email_link.url ? (
      <Link
        variant="iconDark"
        aria-label="email"
        to={email_link.url}
        sx={{ mx: 2 }}
      >
        <AiOutlineMail size={32} />
      </Link>
    ) : null}
    {instagram_link.url ? (
      <Link
        variant="iconDark"
        aria-label="instagram"
        to={instagram_link.url}
        sx={{ mx: 2 }}
      >
        <AiOutlineInstagram size={32} />
      </Link>
    ) : null}
    {facebook_link.url ? (
      <Link
        variant="iconDark"
        aria-label="facebook"
        to={facebook_link.url}
        sx={{ mx: 2 }}
      >
        <AiFillFacebook size={32} />
      </Link>
    ) : null}
    {twitter_link.url ? (
      <Link
        variant="iconDark"
        aria-label="twitter"
        to={twitter_link.url}
        sx={{ mx: 2 }}
      >
        <AiOutlineTwitter size={32} />
      </Link>
    ) : null}
    {linkedin_link.url ? (
      <Link
        variant="iconDark"
        aria-label="linkedin"
        to={linkedin_link.url}
        sx={{ mx: 2 }}
      >
        <AiFillLinkedin size={32} />
      </Link>
    ) : null}
    {website_links
      ? website_links.map(doc => (
          <Link
            key={doc.link.url}
            variant="iconDark"
            aria-label="website"
            to={doc.link.url}
            sx={{ mx: 2 }}
          >
            <FiGlobe size={32} />
          </Link>
        ))
      : null}
  </Flex>
)
SocialLinks.propTypes = ContributorSocialLinkPropTypes
