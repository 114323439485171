/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Flex, Text } from "theme-ui"

import { GatsbyImagePropType, ContributorPropTypes } from "../utils/propTypes"
import { RichText } from "./RichText"
import { SocialLinks } from "./SocialLinks"
import { ResponsiveImage } from "./ResponsiveImage"

const Contributor = ({ contributor }) => {
  const { name, profile_picture, biography } = contributor.document.data

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex sx={{ alignItems: "center", mr: 3, mt: 3 }}>
        <ResponsiveImage
          image={profile_picture}
          sx={{
            borderRadius: "50%",
            mr: 2,
            // Note: have to fix the width and height with ~important
            // to work around a limitation in Prismic previews
            // where fixed images are always fetched with a default 400px width
            // See https://github.com/prismicio/prismic-gatsby/blob/main/packages/gatsby-plugin-prismic-previews/docs/limitations.md#image-processing
            width: "40px !important",
            height: "40px !important",
          }}
        />
        <Text sx={{ mr: 3 }}>{name}</Text>
        <SocialLinks {...contributor.document.data} />
      </Flex>
      <RichText text={biography} />
    </Flex>
  )
}
Contributor.propTypes = ContributorPropTypes

const ProfilePictures = ({ profilePictures }) => {
  return (
    <ul
      sx={{
        m: 0,
        p: 0,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
      }}
    >
      {profilePictures.map((profilePicture, i) => (
        <li key={i} sx={{}}>
          <ResponsiveImage
            image={profilePicture}
            sx={{
              border: "solid 2px white",
              borderRadius: "50%",
              // Note: have to fix the width and height with ~important
              // to work around a limitation in Prismic previews
              // where fixed images are always fetched with a default 400px width
              // See https://github.com/angeloashmore/gatsby-source-prismic/blob/main/docs/previews-api.md#images
              width: "40px !important",
              height: "40px !important",
              ml: i !== 0 ? -2 : 0,
            }}
          />
        </li>
      ))}
    </ul>
  )
}
ProfilePictures.propTypes = {
  profilePictures: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string,
      gatsbyImageData: GatsbyImagePropType,
    })
  ),
}

export const ContributorsFull = ({ contributors }) => (
  <Flex sx={{ flexWrap: "wrap" }}>
    {contributors.map(contributor => (
      <Contributor
        key={contributor.contributor.document.data.name}
        {...contributor}
      />
    ))}
  </Flex>
)
ContributorsFull.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.shape(ContributorPropTypes)),
}

export const ContributorsArticleCard = ({ contributors, styles }) => {
  const profilePictures = contributors
    .map(contributor => contributor.contributor.document.data.profile_picture)
  const names = contributors.map(
    contributor => contributor.contributor.document.data.name
  )

  return (
    <Flex sx={{ alignItems: "center", ...styles }}>
      <ProfilePictures profilePictures={profilePictures} />
      <Text
        sx={{
          ml: 3,
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          overflow: "hidden",
        }}
      >
        {names.join(", ")}
      </Text>
    </Flex>
  )
}
ContributorsArticleCard.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.shape(ContributorPropTypes)),
  styles: PropTypes.object,
}

export const ContributorsArticle = ({
  contributors,
  publishedDate,
  styles,
}) => {
  const profilePictures = contributors.map(
    contributor => contributor.contributor.document.data.profile_picture
  )
  const names = contributors.map(
    contributor => contributor.contributor.document.data.name
  )

  return (
    <Flex sx={{ alignItems: "center", ...styles }}>
      <ProfilePictures profilePictures={profilePictures} />
      <Flex sx={{ flexDirection: "column", ml: 2 }}>
        <Text>{names.join(", ")}</Text>
        <Text variant="fineprint" color="gray">
          {publishedDate}
        </Text>
      </Flex>
    </Flex>
  )
}
ContributorsArticle.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.shape(ContributorPropTypes)),
  publishedDate: PropTypes.string,
  styles: PropTypes.object,
}
