/** @jsx jsx */

import { createElement } from "react"
import PropTypes from "prop-types"
import { jsx, Grid } from "theme-ui"

export const ItemsGrid = ({
  items,
  component,
  emphasizeFirst,
  itemProps,
  gap
}) => {
  return (
    <Grid gap={gap || 3} columns={[null, null, 2, 4]}>
      {items.map((item , i) => {
        const isEmphasized = emphasizeFirst && i === 0

        return createElement(
          component,
          {
            key: i,
            isEmphasized,
            styles: {
              gridColumnStart: isEmphasized
                ? [null, null, "span 2", "span 4"]
                : "auto",
            },
            ...item,
            ...itemProps
          }
        )
      })}
    </Grid>
  )
}
ItemsGrid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  component: PropTypes.func,
  emphasizeFirst: PropTypes.bool,
  itemProps: PropTypes.object,
  gap: PropTypes.number
}
