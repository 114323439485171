/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Text, Flex } from "theme-ui"

import { ArticleTagPropTypes } from "../utils/propTypes"

const Tag = ({ name, styles }) => (
  <Text
    sx={{
      display: "inline-block",
      borderRadius: 4,
      px: 3,
      py: 2,
      mr: 2,
      my: 1,
      color: "white",
      bg: "soilBrown",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      ...styles,
    }}
  >
    {name}
  </Text>
)
Tag.propTypes = {
  name: PropTypes.string.isRequired,
  styles: PropTypes.object,
}

const TagsPropTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape(ArticleTagPropTypes)).isRequired,
}

const TagsBase = ({ tags, styles }) => (
  <Flex sx={{ mb: 3, flexWrap: "wrap", ...styles }}>
    {tags.map(tag => {
      const name = tag.category.document.data.name
      return <Tag key={name} name={name} />
    })}
  </Flex>
)
TagsBase.propTypes = {
  ...TagsPropTypes,
  styles: PropTypes.object,
}

// These values are got by roughly estimating
// actual sizes from dev tools
// Since we use ems, they should be scalable across mobile devices
const EMS_PER_CHARACTER = 0.5
// px = 16px; mr = 8px
const EMS_PADDING_MARGIN_PER_CARD = (16 * 2 + 8) / 16
// const EMS_PER_CHARACTER = 0.48
const MAX_CARD_CONTENT_WIDTH_IN_EM = [16]

// This component has additional styling logic for truncating
// the number of tags shown since there is limited space on
// an article card. It will only show as many tags as can fit
// into the max card content width as specified in the constant above
const TagsForCard = ({ tags }) => {
  const tagsWithWidths = tags.reduce((acc, tag, i) => {
    const name = tag.category.document.data.name
    const minWidth = name.length * EMS_PER_CHARACTER
    const previousWidth = acc[i - 1]?.cumulativeWidth || 0
    const cumulativeWidth =
      previousWidth + minWidth + EMS_PADDING_MARGIN_PER_CARD
    return acc.concat({ tag, minWidth, cumulativeWidth })
  }, [])

  const tagsWithStyles = tagsWithWidths.map(tagWithWidth => ({
    tag: tagWithWidth.tag,
    minWidth: tagWithWidth.minWidth,
    display: MAX_CARD_CONTENT_WIDTH_IN_EM.map(max =>
      tagWithWidth.cumulativeWidth < max ? "inline-block" : "none"
    ),
  }))

  return (
    <Flex sx={{ mb: 3, overflow: "hidden" }}>
      {tagsWithStyles.map(tagWithStyles => {
        const name = tagWithStyles.tag.category.document.data.name
        const { display, minWidth } = tagWithStyles
        return <Tag key={name} name={name} styles={{ display, minWidth }} />
      })}
    </Flex>
  )
}
TagsForCard.propTypes = TagsPropTypes

export const Tags = ({ isForCard, tags, styles }) => {
  if (isForCard) {
    return <TagsForCard tags={tags} />
  }

  return <TagsBase tags={tags} styles={styles} />
}
Tags.propTypes = {
  ...TagsPropTypes,
  isForCard: PropTypes.bool,
  styles: PropTypes.object,
}
