/** @jsx jsx */
import PropTypes from "prop-types"
import { IoFlowerOutline, IoBook } from "react-icons/io5"
import { GoMegaphone } from "react-icons/go"
import { jsx, Card, Heading, Flex, Text, Paragraph } from "theme-ui"

import {
  PrismicPlainTextFieldPropType,
  ArticlePropTypes,
  ArticleDataPropTypes,
} from "../utils/propTypes"
import { extractExcerpt } from "../utils/articleUtils"
import { Link } from "./Link"
import { Tags } from "./Tags"
import { ResponsiveImage } from "./ResponsiveImage"
import { ContributorsArticleCard } from "./Contributors"

const FeaturedBadge = () => (
  <Flex
    sx={{
      borderRadius: 2,
      py: 1,
      px: 2,
      color: "white",
      bg: "beetrootRed",
      position: "absolute",
      top: [3, 3, 4],
      left: [4, 4, 5],
      zIndex: 1,
    }}
  >
    <IoFlowerOutline />
    <Text variant="fineprint" sx={{ pl: 1 }}>
      Featured
    </Text>
  </Flex>
)

const JournazineBadge = ({ issue_title }) => (
  <Flex
    sx={{
      width: "max-content",
      alignItems: "center",
      borderRadius: 4,
      px: 3,
      py: 2,
      mr: 2,
      my: 1,
      color: "white",
      bg: "darkSoilBrown",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
  >
    <IoBook />
    <Text sx={{ ml: 2 }}>
      <em>The Sauce</em> - {issue_title.text}
    </Text>
  </Flex>
)
JournazineBadge.propTypes = {
  issue_title: PrismicPlainTextFieldPropType,
}

const OpenCallBadge = ({ title }) => (
  <Flex
    sx={{
      width: "max-content",
      alignItems: "center",
      borderRadius: 4,
      px: 3,
      py: 2,
      mr: 2,
      my: 1,
      color: "white",
      bg: "darkGray",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }}
  >
    <GoMegaphone />
    <Text sx={{ ml: 2 }}>{title.text}</Text>
  </Flex>
)
OpenCallBadge.propTypes = {
  title: PrismicPlainTextFieldPropType,
}

export const ArticleBriefCard = ({
  url,
  isEmphasized,
  is_featured,
  cover_image,
  displayBadges,
  open_call,
  journazine_issue,
  tags,
  publication_date,
  first_publication_date,
  title,
  contributors,
  body,
  styles,
}) => {
  return (
    <Link
      variant="card"
      to={url}
      sx={{
        position: "relative",
        textAlign: "left",
        minHeight: isEmphasized ? 0 : [null, null, "480px", "480px"],
        // Card doesn't seem to fill link element on mobile unless height is
        // explicitly set; maxHeight alone seems to work on desktop though
        height: ["auto", "auto", isEmphasized ? "450px" : "auto"],
        ...styles,
      }}
    >
      <Card
        data-testid={`article-card-${title.text}`}
        sx={{ height: "100%", flexDirection: isEmphasized ? "row" : "column" }}
      >
        {displayBadges && is_featured ? <FeaturedBadge /> : null}
        <ResponsiveImage
          image={cover_image}
          sizes={
            isEmphasized ? [null, null, "500px"] : ["300px", "700px", "300px"]
          }
          sx={{
            flex: isEmphasized ? 2 : "none",
            height: isEmphasized ? "auto" : "200px",
            width: isEmphasized ? "60%" : "auto",
          }}
        />
        <Flex
          sx={{
            flexDirection: "column",
            p: 4,
            flex: isEmphasized ? 3 : 1,
          }}
        >
          {displayBadges && journazine_issue.document ? (
            <JournazineBadge
              issue_title={journazine_issue.document.data.issue_title}
            />
          ) : null}
          {displayBadges && open_call.document ? (
            <OpenCallBadge title={open_call.document.data.title} />
          ) : null}
          <Tags tags={tags} isForCard={true} />
          <Text variant="fineprint" sx={{ mb: 3 }}>
            {publication_date || first_publication_date}
          </Text>
          <Heading
            sx={{
              mb: 3,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              overflow: "hidden",
            }}
          >
            {title.text}
          </Heading>
          {isEmphasized ? (
            <Paragraph
              sx={{
                mb: 3,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: [null, null, 4, 6],
                overflow: "hidden",
              }}
            >
              {extractExcerpt(body, 500)}
            </Paragraph>
          ) : null}
          <ContributorsArticleCard
            contributors={contributors}
            styles={{ mt: "auto" }}
          />
        </Flex>
      </Card>
    </Link>
  )
}
ArticleBriefCard.propTypes = {
  isEmphasized: PropTypes.bool,
  displayBadges: PropTypes.bool,
  styles: PropTypes.object,
  ...ArticleDataPropTypes,
  first_publication_date: ArticlePropTypes.first_publication_date,
}
